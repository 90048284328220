import React from "react";
import Layout from "../../common/Layout/Layout";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../home.css";
import { Link } from "react-router-dom";
import Header from "../../common/Layout/Header/Header";

const SignUp_success = (props) => {
  return (

    <Layout>
      <div >
        <div className="sr-field-success message">
          <h1 style={{ color: "orange" }}>Signed up successfully</h1>
          <Link to="/" style={{ color: "black" }} >Click here to login </Link>
        </div>
      </div>
    </Layout>

  );
};
export default SignUp_success;