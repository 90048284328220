import { GlobalVariable } from "./baseUrl";

const createPaymentIntent = options => {
  return window
    .fetch(`${GlobalVariable.BACKEND_API_URL}/create-payment-intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
         "token":localStorage.getItem("token")
      },
      body: JSON.stringify(options)
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        // console.log("API error:", { data });
        throw new Error("PaymentIntent API Error");
      } else {
        return data;
      }
    });
};

const getProductDetails = options => {
  return window
    .fetch(`${GlobalVariable.BACKEND_API_URL}/product-details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "token":localStorage.getItem("token")
      }
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        // console.log("API error:", { data });
        throw Error("API Error");
      } else {
        return data;
      }
    });
};

const getPublicStripeKey = options => {
  return window
    .fetch(`${GlobalVariable.BACKEND_API_URL}/public-key`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "token":localStorage.getItem("token")
      }
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        // console.log("API error:", { data });
        throw Error("API Error");
      } else {
        return data.publishableKey;
      }
    });
};

const api = {
  createPaymentIntent,
  getPublicStripeKey: getPublicStripeKey,
  getProductDetails: getProductDetails
};

export default api;
