import React from "react";
import Layout from "../../common/Layout/Layout";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../home.css"
import Header from "../../common/Layout/Header/Header";

const Forgotpwd_success = (props) => {
  return (

    <Layout>
      <div>
        <div className="sr-field-success message">
          <h1 style={{ color: "orange" }}>Reset link is sent to your Email </h1>
        </div>
      </div>
    </Layout>


  );
};
export default Forgotpwd_success;