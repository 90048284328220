import React,{useContext,useEffect} from "react";
import { Link, Redirect } from "react-router-dom";
import Footer from "../../common/Layout/Footer/Footer";
import Header from "../../common/Layout/Header/Header";
import Layout from "../../common/Layout/Layout";
import { AppContext } from "../../context/AppContext";
const RenderSuccess = (props) => {
  const {succeeded, setOrderIdArr, setInvoiceIdArr} = useContext(AppContext)
  useEffect(()=>{
    setTimeout(function(){
      window.location.href = 'http://localhost:3000/select';
   }, 10000)
  },[])
  if(!succeeded){
    return <Redirect to="/payment"/>
  }
  if(succeeded){
   
    return (
      <div>
        <Header />
        <div>
          <div className="sr-field-success message">
            <h1 style={{ color: "green" }}>Your payment succeeded</h1>
            <Link to="/select" role="button" style={{color:"orange"}}>
              Book more
            </Link>
          </div>
        </div>
        <footer
          className="p-3"
          style={{
            position: "absolute",
            bottom: 0,
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div className="text-center text-white">
            <p>
            <Link to="/privacypolicy">Privacy Policy </Link>
        <Link to="/termsandconditions"> Terms and Conditions</Link>
        <Link to="/refundpolicy">Booking & Refund Policy</Link>
            </p>
          </div>
        </footer>
      </div>
    );
  }
 
};
export default RenderSuccess 
