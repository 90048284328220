import React, { useState, useContext } from "react";
import "../../home.css";
import { AppContext } from "../../context/AppContext";

const DropDown = () => {
  const { carSelect, setCarSelect } = useContext(AppContext);

  const handleChange = (event) => {
    setCarSelect(event.target.value);
    localStorage.setItem('carSelect', event.target.value)
  };
  {
    // console.log("value:", carSelect);
  }

  return (
    <div>
      <p className="forgot_password text-start mt-3"></p>
      <select
        className={
          carSelect == 0
            ? `form-select form_bg mt-1 p-2 `
            : `form-select form_bg mt-1 p-2 selected `
        }
        aria-label="Default select example"
        onChange={handleChange}
        value={carSelect}
      >
        <option value="0">Number of Cars to be Inspected</option>
        {/* <option value="1">One</option> */}
        <option value="2">Two</option>
        <option value="3">Three</option>
        <option value="4">Four</option>
        <option value="5 ">Five</option>
        <option value="6">Six</option>
      </select>
    </div>
  );
};

export default DropDown;
