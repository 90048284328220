import React, { Component, useContext, useEffect, useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "../../common/button/Button";
import Layout from "../../common/Layout/Layout";
import "../../home.css";
import Input from "../../common/input/Input";
import { AppContext } from "../../context/AppContext";
import { useHistory } from "react-router-dom";
import { GlobalVariable } from "../../common/api/baseUrl";
// import { userContext } from "../../context/carContext";
const axios = require("axios");
const bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);
const Forgotpwd = ({ ...props }) => {
  let history = useHistory();
  const {
    setRegisterdEmail,
    registerdEmail
  } = useContext(AppContext);
  const [forgotPwdMsg,setForgotPwdMsg] = useState("")
  const reset = () => {
      // const pwd = bcrypt.hashSync(userPassword, salt);
      axios.post(`${GlobalVariable.BACKEND_API_URL}/updatepwd`, {email:registerdEmail})
    .then((res) => {
       history.push("/forgotpwd_success")
      localStorage.setItem("userId",res.data.userId)
    })
    .catch( (err) => {
      if (
        err.response &&
        err.response.data &&
        err.response.data.errorMessage
      ) {
        setForgotPwdMsg(err.response.data.errorMessage);}
    })
  };
  return (
    <Layout>
      <div>
        <div className="text-center text-white">
          <div className="full_bg">
            <section>
              <div className="p-3">
                <form>
                  <div className="mb-5 col-md-3 offset-md-8 mt-4 pt-5 text-start">
                    <div className="mt-4 mb-4">
                      <b className="login_heading">Forgot password</b>
                    </div>
                    <div className="mb-4">
                      <p className="text-start">Enter Registred Email</p>
                      <Input
                        placeholder="Enter Email"
                        name="userName"
                        type="text"
                        value={registerdEmail}
                        onChange={(e) => {
                          setRegisterdEmail(e.target.value);
                        }}
                      />
                    </div>
                    <span className="form-msg">{forgotPwdMsg}</span>
                    <div className="text-end">
                      <Button
                        label="Send Email"
                        onClick={reset}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Forgotpwd;






