import moment from "moment";
import React,{useContext, useState} from "react";
import { AppContext } from "../../context/AppContext";
import "../../home.css";

const TimeSlots = () => {
  const {
    validate, 
    selectedSlots, 
    avaliableSlots, 
    updateSelectedSlots,
    selectedDate, autoSelectSlot} = useContext(AppContext)
   
  return (
    <div>
      <div className="time_style col-md-12 float-start">
        <div className="mb-4">
          { selectedDate ? ( <p>Pick Time</p>): null}
          {avaliableSlots && avaliableSlots.map((slot, i) => {
            return (
              <div className="check box" key={i}>
              <label>
              <input type="checkbox" value="1" 
                    checked={selectedSlots.indexOf(slot.time) !== -1}
                    onChange={()=>{
                      updateSelectedSlots(slot.time, i)
                  }}
                  />
                  <span>{moment(slot.time,'h:mm A').format('h:mm A')}</span>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default TimeSlots;
