import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import "../../home.css";

const Content = () => {
  const { userName } = useContext(AppContext);
  const user = localStorage.getItem('userName') //userName
  return (
    <div>
      <div className="row">
        <div className="col forgot_password">
          <p className="welcome_heading text-start mt-4">
            Welcome <b>{user}</b>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Content;
