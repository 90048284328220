import React from "react";
import "../../home.css";

const Button = ({ label, ...props }) => {
  return (
    <div>
      <button
        type="button"
        className="btn primay primary_color text-white m-2"
        {...props}
      >
        {label}
      </button>
    </div>
  );
};
export default Button;
