import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import $ from "jquery";
import Popper from "popper.js";
import { AppContextProvider } from "./context/ContextProvider";

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </React.StrictMode>,

  document.getElementById("root")
);
