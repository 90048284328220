import React, { useState } from "react";
import "../../home.css";

const Input = ({ placeholder, onChange, type, name }) => {
  return (
    <div>
      <input
        type={type}
        name={name}
        className="form-control mb-4 p-2"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};
export default Input;
