import React, { useContext, useEffect,useRef } from "react";
import moment from "moment";
import "../../home.css";
import Content from "../../common/content/Content";
import Layout from "../../common/Layout/Layout.js";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../common/form/checkoutform";
import api from "../../common/api/stripeapi"
import { Redirect } from "react-router-dom";

const Payment = ({ history }) => {
  const {   carSelect, service, price, selectedSlots, updateGoback,goBack,
    updateSelectedSlots, orderIdArr,setBookingSucess } =
    useContext(AppContext);
  const selectedslot = localStorage.getItem('selectedSlots')
  useEffect(() => {
    service();
  }, [orderIdArr])
  const logoutTimerIdRef = useRef(null);
  const logoutUser =()=>{
      history.push("/")
  }
  useEffect(() => {
    const autoLogout = () => {
      if (document.visibilityState === 'hidden') {
        const timeOutId = window.setTimeout(logoutUser,12 * 60 *60 * 1000);
        logoutTimerIdRef.current = timeOutId;
      } else {
        window.clearTimeout(logoutTimerIdRef.current);
      }
    }
    document.addEventListener('visibilitychange', autoLogout);
    return () => {
      document.removeEventListener('visibilitychange', autoLogout);
    };
  }, [])
  if(localStorage.getItem("token") === undefined  || !localStorage.getItem("token")){
    return (<Redirect to="/" />)
  }
  else if(localStorage.getItem("token") && localStorage.getItem("selectedDate")){

  let changeDate = localStorage.getItem('selectedDate')//selectedDate;
  changeDate = changeDate.split("-");
  const stripePromise = api.getPublicStripeKey().then(key => loadStripe(key));
  const carselect = localStorage.getItem('carSelect');
  return (
    <Layout>
      <div className="text-center text-white">
        <div className="body_height">
          <section className="container car_choosen">
            <Content />
            <div className="row">
              <button className="fs-6 mb-3" onClick={() => {
                setBookingSucess(false)
                history.push("/")
                }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  ></path>
                </svg>
                Back
              </button>
            </div>
            <div className="row">
              <div className="col-md-3 order-md-2 text-start m-3">
                <div className=" inspection_cost">
                  <span></span>
                  <p className="mt-4 date mb-0">
                    <b>Inspection Date</b>{" "}
                  </p>
                  <p>
                    {changeDate ? <>{changeDate[2]}-{changeDate[1]}-{changeDate[0]}</> : ''}
                  </p>
                  <p className="mt-4 date mb-0">
                    <b>Inspection time</b>{" "}
                  </p>
                  {selectedSlots.map((slot, i) => {
                    return (
                      <div className="time-slot" key={i}>
                        <p>
                          <span>
                            {moment(slot, "h:mm a").format("h:mm a")}
                          </span>
                        </p>
                      </div>
                    );
                  })}
                  <p className="cost">
                    <span>Inspection Cost </span>{" "}
                    <b>
                      ${price}*{localStorage.getItem("carSelect")}
                    </b>
                  </p>
                  <p className="total mb-1">
                    <span>Your Total</span> <b> ${price * localStorage.getItem("carSelect")}</b>
                  </p>
                  <p className="gst">
                    <span>Incld GST</span>
                  </p>
                </div>
              </div>

              <div className="col-md-5 order-md-1 m-3">
                <Elements stripe={stripePromise} >
                  <CheckoutForm />
                </Elements>
              </div>
            </div>
          </section>
        </div>
      </div>
   
    </Layout>
  );
}};
export default withRouter(Payment);