import React from "react";
import "../../../home.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="p-3 c-3">
    <div className="text-center text-white">
      <p>
        {/* <Link to="/privacypolicy" target="_blank">Privacy Policy |</Link>
        <Link to="/termsandconditions" target="_blank"> Terms and Conditions</Link> */}
          {/* <Link to="/refundpolicy" target="_blank"> Booking & Refund Policy</Link> */}
        <a href="https://safebuy.com.au/read-the-polices-of-prepurchase-vehicle-services/" target="_blank">Privacy Policy |</a>
        <a href="https://safebuy.com.au/read-the-terms-conditions-of-prepurchase-vehicle-services/" target="_blank"> Terms and Conditions</a>
      </p>
    </div>
  </footer>
  )
}
export default Footer;
