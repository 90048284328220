import React from "react";
import Layout from "../Layout/Layout";
import "./terms-conditions.css";

const TermsConditions = (props) => {
  const render = () => {
    return (
    <Layout>

        <div className="TermsConditionsContainer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="TermsConditionsTitle">TERMS AND CONDITIONS</h1>
                <p>
                  By booking a vehicle inspection by SafeBuy, you are agreeing
                  to the terms and conditions below:&nbsp;
                </p>
                <p>
                  <strong>Disclosure</strong>
                </p>
                <p>
                  WATCH OUT, always verify in advance for a clear title before
                  paying for vehicle.&nbsp;
                </p>
                <p>1300 799 523 or online at www.carhistory.com.au&nbsp;</p>
                <p>
                  <strong>Disclaimer:</strong>
                </p>
                <p>
                  <strong>
                    <em>
                      We encourage our clients to get in touch with their
                      inspector for possible explanation offaults/problems that
                      are found. Vehicle’s authenticity/verification is solely
                      the sole liabilityof the buyer. VIN, engine numbers and
                      chassis number are being checked for
                      falsification,however, the report is no guarantee or
                      warranty for any objective/purpose.
                    </em>
                  </strong>
                </p>
                <p>
                  Inspection and assessment through visually and noise
                  appreciation only and that no changeshave been made neither
                  components of the car is disassembled/changes to verify
                  diagnoses.
                </p>
                <p>
                  However, SafeBuy Inspectors make sure that each detail was
                  going to be documented at thetime of inspection. But,
                  Inspectors will not be able to report on the defect if they
                  can’t see itor it is not visible during the inspection. In
                  case of vehicle is not drivable or not in workingorder then
                  Inspector will not be responsible to provide detail of engine
                  and transmissionperformance. ECU scan will not be performed if
                  car battery is dead or car is manufacturedprior to 1996.
                  Inspection summary indicates the Exterior, Interior and
                  Mechanical conditionon the report.
                </p>
                <li>
                  SafeBuy conduct inspection VISUALLY and non-mechanical only.
                  SafeBuy inspector will not be liable for faults which are
                  found later and were not obvious through visual or noticeable
                  during the inspection. Our inspectors use latest technology
                  tools and acceptable procedure to detect any faults but these
                  tools and procedures does not essentially detect every problem
                  or faults in the vehicle. The make and condition of the
                  vehicle should be considered and the report should be reviewed
                  within this context.&nbsp;
                </li>
                <li>
                  The inspection report is current only as the time the
                  inspection was performed. The buyer is responsible to check
                  and inspect the vehicle visually before making the purchase.
                  The report should not be considered or treated as certificate
                  of roadworthy - the appropriate roadworthy authority of your
                  state or territory should be contacted for roadworthy
                  certificate provisions.
                </li>
                <li>
                  Inspection does not guarantee that the vehicle is roadworthy
                  or might be able to pass the roadworthy test.
                </li>
                <li>
                  Before the vehicle undertakes the road drive test as the part
                  of inspection, the customer must make sure that the vehicle
                  could be driven safely, condition is roadworthy, registration
                  is latest and has sufficient fuel. Vehicle would only be
                  tested on the road withthe allowed speed of the local area.
                </li>
                 {/*addded a vehicle */}
                <li>
                  Inspector is obliged to call (from private number) to every
                  customer after sending the inspection report during his
                  working hours. Incase of customer can not receive a call then
                  inspector attempt to call few more times. Inspector is not
                  allowed to receive call or text messages for health & safety
                  reason during his working hours. As it may impact the focus of
                  the inspector while completing the report and test driving a
                  vehicle.
                </li>
                <li>
                  In severe weather condition inspection can only be carried out
                  in covered area else required reschedule or cancellation.
                </li>
                <li>
                  SafeBuy inspection report does not provide warranty on any
                  nature, credibility, precision or inclusiveness of any data
                  which is contained in the report or the accuracy of the data
                  onthe report for any other purpose of intention. Our inspector
                  will not verify the recall notice from the vehicle manufacture
                  and the vehicle is not under the recall notice should be
                  confirmed by the vehicle owner.&nbsp;
                </li>{" "}
                <br />
                <p>
                  WHAT WE DO NOT INSPECT:
                  <br />
                </p>
                <li>Brake fluid contamination/hydraulic fluid leak</li>
                <li>ABS system</li>
                <li>Drum brakes and wheel cylinders leak</li>
                <li>Trip computer reading such as oil and fuel consumption</li>
                <li>Cruise control and other computer systems</li>
                <li>Reason for oil leaks</li>
                <li>Oil pump and strainer for sludge build-up</li>
                <li>Oil burning issue</li>
                <li>Cause of vehicle smoke</li>
                <li>Vehicle electronics</li>
                <li>Transmission mechanics</li>
                <li>Engine compression (condition apply)</li>
                <li>Engine mounts</li>
                <li>Water pump</li>
                <li>Water leaks or reason for leaks</li>
                <li>Timing belt, oil pump belt, balancer and drive belt</li>
                <li>Clutch and flywheel</li>
                <li>Repairs or service history of the vehicle</li>
                <li>
                  Functionality of audio or entertainment system including CD,
                  Cassette, TV, DVD, iPod connectivity, sensors, alarm/security
                  devices, satellite navigation systems/GPS
                </li>
                <li>Emissions test</li>
                <li>Catalytic converter</li>
                <li>
                  Aftermarket or additional accessories included as a result of
                  owner’s modifications
                </li>
                <li>Mechanical switching of wipers and lights</li>
                <li>LPG systems</li>
                <li>Fuel filter and fuel consumption</li>
                <li>Air condition performance&nbsp;</li> <br />
                <p>
                  <strong>Note:</strong>&nbsp;Engine compression test requires
                  extra time to perform on top of already designated inspection
                  time. Therefore, it can be completed with additional cost of
                  $50-100. It is customer’s responsibility to take seller
                  permission to perform compression test before makingpayment.
                </p>
                <p>
                  If there is any unease over the accuracy of this report please
                  get in touch with SafeBuy teambefore performing any work. Not
                  any warranty on prior restoration record, measurement ofthe
                  alignment of the frame of the vehicle or component has not
                  been removed. A concludingvisual inspection of a vehicle at
                  time of sale are the responsibility of the buyer, SafeBuy
                  isnot liable for dents/damage or condition modification after
                  preliminary inspection. Clients areadvised to view or print
                  inspection report in colour. Otherwise, please check with
                  SafeBuyfor electronic copy. We encourage our buyers to verify
                  with appropriate authorities inAustralia for any type of
                  write-off record before buying the vehicle. However, our
                  premiuminspection service, we attach car history as separate
                  report. Adds-on are available for PPSR,car history check, car
                  valuation and ECU test under other inspection services.
                </p>
                <p>
                  <strong>
                    Inspection report warranty policy for full refund:
                  </strong>
                </p>
                <p>SafeBuy inspection report warranty covers the following:</p>
                <li>For Comprehensive and Premium inspection services.</li>
                <li>
                  Inspector fails to identify fault which was visually apparent
                  at the time of inspecti.
                </li>
                <li>
                  Inspector fails to provide verbal or photo evident what he has
                  inspected.&nbsp;
                </li>{" "}
                <br />
                <p>
                  <strong>Customer Obligations:</strong>
                </p>
                <li>
                  The vehicle will be inspected on the locality advised by the
                  customer (or owner of the vehicle) to SafeBuy. The customer is
                  obligated to make sure that the booked vehicle is at the
                  location mention in the booking at the time of inspection or
                  to acquire the vehicle’s availability at the location at the
                  time of inspection.
                </li>
                <li>
                  The complete report is for the individual name mentioned on
                  the inspection report andmust not be provided to any other 3rd
                  party.
                </li>
                <li>
                  The buyer is responsible to undertake the search the vehicle’s
                  security, if the vehicle is write off or the Vehicle
                  Identification Number or engine number is not authentic. It is
                  not the responsibility of SafeBuy inspector to confirm the
                  accuracy of the mileage of the vehicle.
                </li>
                <li>
                  Any major mechanical repair carried out on the vehicle must be
                  informed to the inspector or if the vehicle has any damage on
                  the structure due to collision or natural damage such as hail
                  or wind damage. SafeBuy rely on the complete release and
                  accuracy of the above information from the buyer/Seller and
                  does not take responsibility if there is any discrepancy in
                  report as the result of the incorrect information given by
                  buyer/selle.&nbsp;
                </li>
                <li>
                  The inspection report of SafeBuy should not be deemed as a
                  suggestion to buy or not to buy the vehicle. The customer
                  should make the decision in regards to matter which you
                  consider are relevant to your investigation and enquiry.&nbsp;
                </li>{" "}
                <br />
                <p>
                  <strong>SafeBuy Car Valuation Policy:</strong>
                </p>
                <p>
                  SafeBuy has prepared car valuation certificate from the
                  information provided by the owner, prepurchase inspection
                  condition report and multiple information sources of the
                  vehicle. SafeBuy is does not take any responsibility for the
                  data given by the vehicle’s owner or auto service sources,
                  accuracy of the estimation made by SafeBuy and should not be
                  relied on the data provided in car valuation certificate
                  without conducting your own assessment of the vehicle.&nbsp;
                </p>
                <p>
                  SafeBuy does not take liability for any potential loss or
                  damage (other than any liability which might not by law be
                  excluded) involving the how you use and rely on this
                  certificate. SafeBuy has applied the most appropriate attempt
                  to give accurate data but does not guarantee or represent any
                  use and reliance on the car valuation certificate.&nbsp;&nbsp;
                </p>
              </div>
            </div>
          </div>
        </div>
        </Layout>
     
    );
  };
  return render();
};
export default TermsConditions;
