import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import '../../home.css';

export const CarDetailsForm = ({ indexVal, elementVal }) => {
  const { setCarDetailsArr, 
  carDetailsFormErrors } = useContext(AppContext);
  const thisYear = new Date().getFullYear();
  const minYear = new Date("January 1, 1950 12:12:00").getFullYear()
  const errorMessages = carDetailsFormErrors ? carDetailsFormErrors[indexVal] : null;
  return (
    <div>
      <input
        className="form-control  p-2"
        placeholder="Make and Model *"
        type="text"
        name="model"
        id = "model"
        dataIndex={indexVal}
        onChange={(e) => {
          const model = e.target.value;
          setCarDetailsArr((currentVal) => {
            currentVal[indexVal] = {
              ...currentVal[indexVal],
              model,
            };
            return currentVal;
          });
        }} 
        required
      />
      {errorMessages && errorMessages['model'] ? (
        <span className="form-msg">{errorMessages['model']}</span>
       ) : null}
      <input
        className="form-control mt-4 p-2"
        placeholder="Manufactured Year *"
        type="number"
        name="year"
        id="year"
        dataIndex={indexVal}
        onBlur={(e)=>{
          if (e.target.value > thisYear || e.target.value < minYear ){
          e.target.value = ""
         }}}
        onInput={(e) => 
        { 
        e.target.value = e.target.value.slice(0, 4)
        }}
        onChange={(e) => {
          const year = e.target.value;
          setCarDetailsArr((currentVal) => {
            currentVal[indexVal] = {
              ...currentVal[indexVal],
              year,
            };
            return currentVal;
          });
        }}
        required
      />
      {errorMessages && errorMessages['year'] ? (
        <span className="form-msg">{errorMessages['year']}</span>
       ) : null}
      <input
        className="form-control mt-4 p-2"
        placeholder="ODO Meter Reading *"
        type="number"
        name="odoMeter"
        id="odoMeter"
        onInput={(e) => {e.target.value = e.target.value.slice(0, 6) }}
        dataIndex={indexVal}
        onChange={(e) => {
          const odoMeter = e.target.value;
          setCarDetailsArr((currentVal) => {
            currentVal[indexVal] = {
              ...currentVal[indexVal],
              odoMeter,
            };
            return currentVal;
          });
        }}
        required
      />
      {errorMessages && errorMessages && errorMessages['odoMeter'] ? (
        <span className="form-msg">{errorMessages['odoMeter']}</span>
       ) : null}
      <input
        className="form-control mt-4 p-2"
        placeholder="Rego Or VIN Number"
        type="text"
        name="regNo"
        id="regNo"
        dataIndex={indexVal}
        onChange={(e) => {
          const regNo = e.target.value;
          setCarDetailsArr((currentVal) => {
            currentVal[indexVal] = {
              ...currentVal[indexVal],
              regNo,
            };
            return currentVal;
          });
        }}
        required
      />
      {errorMessages && errorMessages && errorMessages['regNo'] ? (
        <span className="form-msg">{errorMessages['regNo']}</span>
       ) : null}
        <textarea
        className="form-control mt-4 p-2"
        placeholder="Note:"
        type="text"
        name="carNotes"
        dataIndex={indexVal}
        onChange={(e) => {
          const carNotes = e.target.value;
          setCarDetailsArr((currentVal) => {
            currentVal[indexVal] = {
              ...currentVal[indexVal],
              carNotes,
            };
            return currentVal;
          });
        }}
        required
      />
    </div>
  );
};
