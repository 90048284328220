let host = window.location.host;
let hostName = host.split(":");
const baseUrl = `${window.location.protocol}//${hostName[0]}`;

export const GlobalVariable = Object.freeze({
  production: false,
  IDLE_SESSION_TIMEOUT: 30, 
  BACKEND_API_URL: `${baseUrl}:2000`,
  BASE_API_URL: `${baseUrl}/safebuy/`,
  RESOURCE_URL: `${baseUrl}/assets/`,
  MIDDLEWARE_URL: `${baseUrl}:3001/api`,
  STRIPE_PUBLISHABLE_KEY: "pk_test_51H6zerD27mfUOCUauCQt4kYGHunLH7IRNHkbDnbEUpGtIyA7SmXzMDOuX1qOywxmzgrhMfYtoLnEOeWZFreqfirZ00dNzRT2AG"
});