import React, { Component, useContext, useEffect, useState } from "react";
import swal from "sweetalert";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "../../common/button/Button";
import Layout from "../../common/Layout/Layout";
import "../../home.css";
import Input from "../../common/input/Input";
import { AppContext } from "../../context/AppContext";
import { useHistory } from "react-router-dom";
import { GlobalVariable } from "../../common/api/baseUrl";
// import { userContext } from "../../context/carContext";

const axios = require("axios");
const bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);


const Resetpwd = ({ ...props }) => {
  let history = useHistory();
  const {
    resetPwd,
    setResetPwd,
    confirmPwd,
    setConfirmPwd,
    setResetMsg,resetMsg
  } = useContext(AppContext);

  const id = localStorage.getItem("userId")
  const reset = () => {
    if (resetPwd === confirmPwd) {
      setResetMsg("")
      axios.post(`${GlobalVariable.BACKEND_API_URL}/reset-pwd`, {
        pwd: confirmPwd,
        userId: id
      }).then((res) => {
        history.push("/password-reset_success")
      })
        // .catch(err => console.log(err))
    } else {
      setResetMsg("password is not match")
    }
  };

  return (
    <Layout>
      <div>
        <div className="text-center text-white">
          <div className="full_bg">
            <section>
              <div className="p-3">
                <form>
                  <div className="mb-5 col-md-3 offset-md-8 mt-4 pt-5 text-start">
                    <div className="mt-4 mb-4">
                      <b className="login_heading">Reset Password</b>
                    </div>
                    <div className="mb-4">
                      <p className="text-start">New password</p>
                      <Input
                        placeholder="Enter a New password"
                        name="userName"
                        type="text"
                        value={resetPwd}
                        onChange={(e) => {
                          setResetPwd(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <p className="text-start">Confirm password</p>
                      <Input
                        placeholder="Confirm password"
                        name="userPassword"
                        type="password"
                        value={confirmPwd}
                        onChange={(e) => {
                          setConfirmPwd(e.target.value);
                        }}
                      />
                    </div>
                    <span className="form-msg">{resetMsg}</span>
                    <div className="text-end">
                      <Button
                        label="Reset"
                        onClick={reset}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Resetpwd;
